import * as React from "react"

const Hero = ({ business }) => {
  return (
    <section className="heroSection pb-5">
      <div className="container-fluid p-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 companyImg">
              <img src={process.env.GATSBY_STATIC_IMAGE_URL + "/local/pic1.jpg"} alt="" />
            </div>
            <div className="col-lg-6 pro-content">
              <h1 className="display-4">{business.name}</h1>
              <div className="d-flex flex-column flex-sm-row">
                <div className="content-stars">
                  <span className="pro-rating">5.0</span>
                  <i className="bi bi-star-fill stars active" />
                  <i className="bi bi-star-fill stars active" />
                  <i className="bi bi-star-fill stars active" />
                  <i className="bi bi-star-fill stars active" />
                  <i className="bi bi-star-fill stars active" />
                  <span className="total_review">(1)</span>
                </div>
                <span className="isYourCompany">
                  <a href="https://portal.swiftcrm.com/marketing/claim/" target="_blank" rel="noreferrer">
                    Is this your company?
                  </a>
                </span>
              </div>
              <div className="content-facts mt-3">
                <span>
                  <a href="#map_container">
                    <i className="bi bi-geo-alt-fill" /> {business.address}
                  </a>
                </span>
                <span>
                  <i className="bi bi-envelope" /> {business.email}
                </span>
                <span>
                  <i className="bi bi-phone" /> {business.phone}
                </span>
              </div>
            </div>
            <div className="col-lg-4" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
