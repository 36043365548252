import * as React from "react"
import Slider from "react-slick"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const Gallery = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
  }

  return (
    <section className="galleryPhotos">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="owl-carousel owl-theme galleryContainer">
              <Slider {...settings}>
                <div className="item">
                  <a href="images/1.jpg" data-lightbox="gallery">
                    <img src={process.env.GATSBY_STATIC_IMAGE_URL + "/panel-300x300.jpg"} alt="" />
                  </a>
                </div>
                <div className="item">
                  <a href="images/2.jpg" data-lightbox="gallery">
                    <img src={process.env.GATSBY_STATIC_IMAGE_URL + "/panel2-300x300.jpg"} alt="" />
                  </a>
                </div>
                <div className="item">
                  <a href="images/3.jpg" data-lightbox="gallery">
                    <img src={process.env.GATSBY_STATIC_IMAGE_URL + "/panel3-300x300.jpg"} alt="" />
                  </a>
                </div>
                <div className="item">
                  <a href="images/1.jpg" data-lightbox="gallery">
                    <img src={process.env.GATSBY_STATIC_IMAGE_URL + "/panel-300x300.jpg"} alt="" />
                  </a>
                </div>
                <div className="item">
                  <a href="images/2.jpg" data-lightbox="gallery">
                    <img src={process.env.GATSBY_STATIC_IMAGE_URL + "/panel2-300x300.jpg"} alt="" />
                  </a>
                </div>
                <div className="item">
                  <a href="images/3.jpg" data-lightbox="gallery">
                    <img src={process.env.GATSBY_STATIC_IMAGE_URL + "/panel3-300x300.jpg"} alt="" />
                  </a>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Gallery
