import * as React from "react"
import { useEffect, useState } from "react"

import Layout from "../../../../../../components/Layout/layout"
import Seo from "../../../../../../components/seo"
import Hero from "../../../../../../components/Business/hero"
import Gallery from "../../../../../../components/Business/gallery"
import Detail from "../../../../../../components/Business/detail"

import { getLocationStorage } from "../../../../../../services/locationHelper"

import "../../../../../../css/local_list.css"
import "../../../../../../css/local_detail.css"

const { unSlugify } = require("../../../../../../services/slugHelper")

const IndexPage = props => {
  const [business, setBusiness] = useState({})

  const city = typeof props.city !== "undefined" ? unSlugify(props.city) : ""
  const category = typeof props.category !== "undefined" ? unSlugify(props.category) : ""

  useEffect(() => {
    fetch(`${process.env.GATSBY_API_URL}/city-industry/get-business/${city}/${category}/${props.business}`)
      .then(response => response.json())
      .then(result => {
        setBusiness(result.data)
      })

    let locationStorage = getLocationStorage()
    locationStorage.countryCode = locationStorage.country

    document.body.classList.add("ClientToVendor")
  }, [city, category, props.business])

  return (
    <Layout>
      <Seo title="Best Local Auto Mechanic - Detail page" />
      <Hero business={business} />
      <Gallery />
      <Detail />
    </Layout>
  )
}

export default IndexPage
