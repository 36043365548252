import { Link } from "gatsby"
import * as React from "react"
import Quote from "./quote"

const Detail = () => {
  return (
    <section className="py-5 detail_section">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="row">
              <div className="col-lg-12 mb-4 ">
                <p className="fs-5 fw-bold">Nearby & Recommended</p>
                <div className="pro-box">
                  <div className="icon-holder get-started">
                    <img
                      className=""
                      src={process.env.GATSBY_STATIC_IMAGE_URL + "/local/am1.jpg"}
                      alt=""
                      title=""
                    />
                  </div>
                  <div className="pro-content">
                    <div className="content-title">
                      <h3>
                        <Link to="#">J&E Complete Auto Service</Link>
                      </h3>
                      <div className="showCheckBox">
                        <input
                          type="checkbox"
                          name="List"
                          id="ListCheckbox1"
                          className="css-checkbox ml-sm-2"
                          defaultValue="move"
                        />
                        <label
                          htmlFor="ListCheckbox1"
                          className="css-checkbox-label radGroup2 ml-sm-2 mb-0"
                        />
                      </div>
                    </div>
                    <div className="content-stars">
                      <span className="pro-rating">5.0</span>
                      <i className="bi bi-star-fill stars active" />
                      <i className="bi bi-star-fill stars active" />
                      <i className="bi bi-star-fill stars active" />
                      <i className="bi bi-star-fill stars active" />
                      <i className="bi bi-star-fill stars active" />
                      <span className="total_review">(1)</span>
                    </div>
                    <div className="content-subtitle">
                      <span className="about-txt">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
                        has been the industry's standard dummy text ever since the 1500s
                      </span>
                    </div>
                    <div className="content-facts d-none d-sm-block">
                      <span>
                        <i className="bi bi-geo-alt-fill" /> {"{City, State, Pin}"}
                      </span>
                      <span>
                        <i className="bi bi-clock" /> 35 years in business
                      </span>
                    </div>
                    <div className="content-nav d-block d-sm-none">
                      <button className="btn btn-secondary btn-sm btnRequestQuote">Request a Quote</button>
                      <Link to="#" className="btn btn-backlead btn-sm">
                        View Profile
                      </Link>
                      <span className="distanceLabel">
                        <i className="bi bi-signpost-2-fill" /> 10 miles
                      </span>
                    </div>
                  </div>
                  <div className="content-button d-none d-sm-block">
                    <button className="btn btn-secondary btn-lg btnRequestQuote w-100">
                      Request a Quote
                    </button>
                    <Link to="#" className="btn btn-backlead btn-lg d-none d-sm-block">
                      View Profile
                    </Link>
                    <span className="distanceLabel">
                      <i className="bi bi-signpost-2-fill" /> 10 miles
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <h2>About</h2>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
              the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley
              of type and scrambled it to make a type specimen book. It has survived not only five centuries,
              but also the leap into electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,
              and more recently with desktop publishing software like Aldus PageMaker including versions of
              Lorem Ipsum.
            </p>
            <p>
              It is a long established fact that a reader will be distracted by the readable content of a page
              when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal
              distribution of letters, as opposed to using 'Content here, content here', making it look like
              readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as
              their default model text, and a search for 'lorem ipsum' will uncover many web sites still in
              their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on
              purpose (injected humour and the like).
            </p>

            <hr className="my-5" />

            <div className="stats-block rating-stars mb-3">
              <h2>2 Reviews</h2>
              <span className="pro-rating stars big">5.0</span>
              <i className="bi bi-star-fill review-stars active" />
              <i className="bi bi-star-fill review-stars active" />
              <i className="bi bi-star-fill review-stars active" />
              <i className="bi bi-star-fill review-stars active" />
              <i className="bi bi-star-fill review-stars active" />
            </div>

            <div className="reviewsContainer">
              <ul className="review-list">
                <li className="review ">
                  <p className="review-rating-wrap">
                    <span className="review-author">Gene Stukas Landlord</span>
                    <span className="starFill" data-stars="5">
                      <i className="bi bi-star-fill review-stars active" />
                      <i className="bi bi-star-fill review-stars active" />
                      <i className="bi bi-star-fill review-stars active" />
                      <i className="bi bi-star-fill review-stars active" />
                      <i className="bi bi-star-fill review-stars active" />
                    </span>
                  </p>
                  <p className="review-body about-txt">
                    First of all the plumbing job was done excellent! The quality is great and I am very
                    satisfied. The human relations are trustworthy. If I would need new job, I would go to
                    this company only. I would also recommend this company to all my friends without
                    hesitation.
                  </p>
                </li>
                <li className="review ">
                  <p className="review-rating-wrap">
                    <span className="review-author">Mr, Gene Stukas, and Mr. Valery Galperin</span>
                    <span className="starFill" data-stars="5">
                      <i className="bi bi-star-fill review-stars active" />
                      <i className="bi bi-star-fill review-stars active" />
                      <i className="bi bi-star-fill review-stars active" />
                      <i className="bi bi-star-fill review-stars active" />
                      <i className="bi bi-star-fill review-stars active" />
                    </span>
                  </p>
                  <p className="review-body about-txt">
                    First of all the plumbing job was done excellent! The quality is great and I am very
                    satisfied. The human relations are trustworthy. If I would need new job, I would go to
                    this company only. I would also recommend this company to all my friends without
                    hesitation.
                  </p>
                </li>
              </ul>
            </div>

            <hr className="my-5" />

            <h2 className="">FAQs</h2>
            <div className="row">
              <div className="col-lg-12">
                <div className="accordion accordion-flush swiftlocal_accordion" id="faqList">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="faq1">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqBody1"
                        aria-expanded="true"
                        aria-controls="faqBody1"
                      >
                        What is plumbing & what does a plumber do?
                      </button>
                    </h2>
                    <div
                      id="faqBody1"
                      className="accordion-collapse collapse show"
                      aria-labelledby="faq1"
                      data-bs-parent="#faqList"
                    >
                      <div className="accordion-body">
                        <p>
                          Plumbing involves much more than just the pipes and fixtures in a kitchen or
                          bathroom. Plumbing refers to any system that moves liquids for a wide range of
                          reasons. Plumbing can involve pipes, valves, fixtures, pumps, and tanks. HVAC
                          systems, waste removal, and drinking water delivery are some of the most common uses
                          for plumbing, but there are other uses as well.
                          <br />
                          <br />
                          You may need to hire a licensed plumber to do more than unclog pipes, install new
                          faucets, or deal with a leaky toilet. A plumber will have the skills and tools to
                          repair or replace sewage pipes that are cracked or clogged, and they can handle
                          water heater repairs or replacements, too. A plumber can install a new dishwasher,
                          fix a faulty garbage disposal, and get your sink unclogged. If you suspect you have
                          a gas leak, you’ll definitely need to call in a plumber for an emergency visit.
                          There are a lot of variables and considerations when it comes to plumbing
                          installation and repairs, and an experienced plumber will have the tools and
                          knowledge to tackle your next plumbing project with ease.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="faq2">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqBody2"
                        aria-expanded="false"
                        aria-controls="faqBody2"
                      >
                        How to find a local plumber in your area?
                      </button>
                    </h2>
                    <div
                      id="faqBody2"
                      className="accordion-collapse collapse"
                      aria-labelledby="faq2"
                      data-bs-parent="#faqList"
                    >
                      <div className="accordion-body">
                        <p>
                          It makes sense to hire a plumber who routinely services homes in your area because a
                          local pro will be able to get to your place quickly. In order to find the best
                          plumber for your job, first assess what needs to get done. If you just want to
                          unclog a drain, or have a leaky toilet fixed, just about any certified plumber can
                          get the project done. <br />
                          <br />
                          For more complex home repair projects, you may want to check out the qualifications
                          of the plumbers you call. Armed with your ZIP code and a description of the plumbing
                          job that needs to be tackled, you can use Porch to search for an experienced plumber
                          in your area. You can also contact local construction trade unions for
                          recommendations, or ask your neighbors which plumbers they’ve used in the past.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="faq3">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqBody3"
                        aria-expanded="false"
                        aria-controls="faqBody3"
                      >
                        Plumber hourly rate: how much does a plumber cost?
                      </button>
                    </h2>
                    <div
                      id="faqBody3"
                      className="accordion-collapse collapse"
                      aria-labelledby="faq3"
                      data-bs-parent="#faqList"
                    >
                      <div className="accordion-body">
                        <p>
                          It’s easy to find certified plumbers in your area. Either contact a local
                          construction trade union for a list of recommendations, or go online to search in
                          your vicinty. You’ll need to know the scope of the project in order to make sure the
                          plumber is qualified to address the issue. Just about any plumber can tackle a
                          backed-up garbage disposal or leaky toilet. But for bigger jobs, such as installing
                          a new shower, or a new addition to your home, or you suspect you’ve got a gas leak,
                          you may want to vet your pro more carefully to see if they’re up to the task.
                          <br />
                          <br />
                          Plumbers charge varying rates per hour depending on your area. Some plumbers may bid
                          a flat rate for a job, which you can negotiate. When you’re asking a plumber for an
                          estimate, be sure ask for some specific questions: Request the total cost, along
                          with a breakdown of the cost for materials and labor. Ask whether the plumber will
                          provide the materials or if you’ll need to have them on hand. Ask whether cleanup is
                          included. Finally, whether the pro charges a flat rate for the job or whether they
                          charge by the hour.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="faq4">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqBody4"
                        aria-expanded="false"
                        aria-controls="faqBody4"
                      >
                        How long do water heaters last?
                      </button>
                    </h2>
                    <div
                      id="faqBody4"
                      className="accordion-collapse collapse"
                      aria-labelledby="faq4"
                      data-bs-parent="#faqList"
                    >
                      <div className="accordion-body">
                        <p>
                          If you wait too long to replace a water heater, you may have to deal with more
                          expensive repairs down the line. An aging water heater can leak, causing damage to
                          your home. In general, a tank-style water heater will last 8-12 years with good luck
                          and the proper maintenance. If you find rust or corrosion by the temperature and
                          pressure release valve, there’s a good chance you probably need to replace the water
                          heater.
                          <br />
                          <br />
                          Over time, sediment builds up in water heaters. You can extend the life of your
                          water heater by having it flushed yearly by a licensed plumber or water heating
                          professional. If the hot water tank is leaking, it’s rarely repairable. If the water
                          is no longer really hot, but lukewarm or cold, you should call a pro to see if the
                          water heater needs a new electrical element, or whether the heater needs to be
                          replaced. A pro will help you maintain your water heater to extend its life to the
                          fullest and let you know when it makes more sense to replace it.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="faq5">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqBody5"
                        aria-expanded="false"
                        aria-controls="faqBody5"
                      >
                        Water heater cost: how much is a water heater?
                      </button>
                    </h2>
                    <div
                      id="faqBody5"
                      className="accordion-collapse collapse"
                      aria-labelledby="faq5"
                      data-bs-parent="#faqList"
                    >
                      <div className="accordion-body">
                        <p>
                          The cost of a water heater will vary depending on the type you want to install. A
                          conventional, electric tank-style heater will cost the least. A tankless water
                          heater will cost more up front, but will just heat up the amount of water needed at
                          a time, so they are more energy efficient. In fact, it makes sense to consider the
                          cost of running a water heater along with the initial cost of the heater itself. The
                          energy used by a water heater can account for a quarter of a monthly energy bill. In
                          general, gas heaters cost $100-$150 less per year to operate than electric one,
                          tankless, on-demand heaters cost an additional $100 less, on average. Broadly
                          speaking, gas and electric water heaters last 10-15 years, and tankless heaters may
                          last to 20 years (but they are still new in the market). So, be sure to consider the
                          overall costs associated with choosing a water heater beyond the initial cost of the
                          purchase. If you want help selecting a water heater or if you want to have one
                          installed, rely on Porch to connect you with experienced pros in your area.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="faq6">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqBody6"
                        aria-expanded="false"
                        aria-controls="faqBody6"
                      >
                        Water heater troubleshooting: why is there no hot water in my house?
                      </button>
                    </h2>
                    <div
                      id="faqBody6"
                      className="accordion-collapse collapse"
                      aria-labelledby="faq6"
                      data-bs-parent="#faqList"
                    >
                      <div className="accordion-body">
                        <p>
                          If there’s no hot water in your house, you may want to look into how much it will
                          cost to repair or replace your water heater. If you’ve got an electric water heater
                          a plumber will have a list of questions to answer while troubleshooting your issue.
                          Some repairs are pretty simple and straightforward such as replacing a faulty
                          heating element. You may need to replace the thermostat, or check the circuit
                          breaker box to see if the water heater has been tripped. You may have a more serious
                          problem such faulty wiring. The most serious issue of all is a leaky tank. If you
                          have a leaky tank, you ‘ll need to shop for a new water heater. These vary in price
                          and energy efficiency by type of heater. A conventional, tank style heater will cost
                          the least up front. An on-demand style heater will cost more up front but will offer
                          savings in terms of energy efficiency over time. If you you’d like assistance
                          troubleshooting your water heater, or in selecting a new model, call in a
                          professional plumber. We can connect you with the best pros in your area.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Quote />
        </div>
      </div>
    </section>
  )
}

export default Detail
